import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  // 待审核
  list: params => get(`/scPointsApplication/applyRecordList?state=0`, params),
  // 全部
  listAll: params => get(`/scPointsApplication/applyRecordList`, params),
     // 网格树
  gridTree:args => get('/scGridManagement/getTreeList', args),
  // 根据id获取积分详情
  getPointsById: id => get(`/scPointsApplication/getById?id=${id}`),
  // 审核
  getPointsApplication: args => post('/scPointsApplication/verify', args),
  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
